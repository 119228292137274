import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as d3 from 'd3';
import * as algo1 from "./algo1"
// Import all datasets
import ExpertDataset from "./data/ExpertDataset.csv"
import keywords from "./data/keywords.csv"
// Import all datasets
import AppBar from "./Components/AppBar/AppBar"
import Grid from '@material-ui/core/Grid';
import Topics from "./Components/Keywords_Topics/Topics/Topics";
import Keywords from "./Components/Keywords_Topics/Keywords/Keywords";
import FilterContainer from "./Components/Filters/FilterContainer"
import MatrixContainer from "./Components/Matrix/MatrixContainer"
import ScatterplotContainer from "./Components/Matrix/ScatterplotContainer"
import QuartileContainer from "./Components/Matrix/QuartileContainer"
import SimilarityRootContainer from './Components/SimilarityAnalysis/01RootContainer';
import * as $ from 'jquery';
import './App.css'
export class App extends Component {
  constructor(props) {
    super(props)
    this.state = { selected_data: null }
  }
  componentDidMount() {
    //------------------------------------------------------------------- Read keywords data
    var temp_keywords_data;
    var topic_index_to_topic_name;
    d3.csv(keywords).then(data => {
      var topic_month = {} // This is to get the month of each topic in algo1.js
      data.map(item => {topic_month[item['Topic']] = item['Month']})
      this.props.Set_topic_month(topic_month)
      var keyword_freq_arr_by_topic = {}
      var data2 = data.map(item => {
        if (item["Topic"] in keyword_freq_arr_by_topic) { } else { keyword_freq_arr_by_topic[item["Topic"]] = {} }
        item['Keywords'] = item['Keywords'].split(',')
        item['Keywords'].map(keyword => {
          if (keyword in keyword_freq_arr_by_topic[item["Topic"]]) {
            keyword_freq_arr_by_topic[item["Topic"]][keyword] = keyword_freq_arr_by_topic[item["Topic"]][keyword] + 1
          }
          else {
            keyword_freq_arr_by_topic[item["Topic"]][keyword] = 1
          }
        })
        //item["Topic_index"] = parseInt(item["Topic_index"])
        return item
      })
      var final_keyword_freq_arr_by_topic = [] // Make the final array with keyword frequency
      data.map(item => {
        item["keyword_freq"] = keyword_freq_arr_by_topic[item['Topic']]
        final_keyword_freq_arr_by_topic.push(item)
      })
      this.props.Set_keyword_freq_arr_by_topic(final_keyword_freq_arr_by_topic)
      this.props.Set_keywords_data(data2)
      topic_index_to_topic_name=d3.nest().key(function (d) { return d.Month; }).key(function (d) { return d.Topic_index; })
      .rollup(function (d) { return d[0]['Topic']; }).object(data2);
      temp_keywords_data = d3.nest().key(function (d) { return d.Month; }).key(function (d) { return d.Topic_index; }).rollup(function (d) { return d[0]['Keywords']; }).object(data2);
    
    }).then(()=>{
    //------------------------------------------------------------------- Read Expert data
    d3.csv(ExpertDataset).then(data => {
      var temp_sentiments_set = new Set()
      var temp_data_by_month = {}
      data.map(item => {
        temp_sentiments_set.add(item["FlairSentiment"]) // Get all available sentiments
        item["SubjectivityScore"] = parseFloat(item["SubjectivityScore"]) // convert strings to float
        item["FlairSentimentScore"] = parseFloat(item["FlairSentimentScore"]) // convert strings to float
        item["Top10Keywords"] = temp_keywords_data[item['Month']][item['Topic_index']]
        item["Text2"] = item["Text"].split(" ")
        item["Topic"]=topic_index_to_topic_name[item['Month']][item['Topic_index']]
        if (item['Month'] in temp_data_by_month) { temp_data_by_month[item['Month']].push(item) } // if the key exist, just append
        else { temp_data_by_month[item['Month']] = []; temp_data_by_month[item['Month']].push(item) } // if key doesn't exist, 
        //console.log(item)
      })
      //-------------------------------------------------------------------
      //console.log(temp_data_by_month[this.props.selected_month])
      this.props.Set_FlairSentimentScoreRange(algo1.getRange(temp_data_by_month[this.props.selected_month], "FlairSentimentScore")) // Set FlairSentimentScore
      this.props.Set_scoresSubjectivityRange(algo1.getRange(temp_data_by_month[this.props.selected_month], "SubjectivityScore")) // Set SubjectivityScore
      this.props.Set_Selected_FlairSentimentScoreRange(algo1.getRange(temp_data_by_month[this.props.selected_month], "FlairSentimentScore")) // Set Selected_FlairSentimentScore
      this.props.Set_Selected_scoresSubjectivityRange(algo1.getRange(temp_data_by_month[this.props.selected_month], "SubjectivityScore")) // Set Selected_scoresSubjectivity

      this.props.Set_FlairSentiments(Array.from(temp_sentiments_set)) // Set sentiments
      this.props.Set_Months(Object.keys(temp_data_by_month)) // Set months
      this.props.Set_original_data(temp_data_by_month) // Set original data by month
      
      //-------------------------------------------------------------------
    })
  })
  }
  render() {
    return (
      <Grid container direction="row" spacing={0}>
        {this.props.original_data != null ?
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <Grid className="topics" style={{ marginTop: 1 }} item xs={12}><Topics></Topics></Grid>
            {
              // <Grid className="kewwords" style={{ marginTop: 2 }} item xs={12}><Keywords></Keywords></Grid>
            }
            <Grid className="filters" style={{ marginTop: 1 }} item xs={12}><FilterContainer></FilterContainer></Grid>
            {
              this.props.view=="Default"?<Grid className="matrix" style={{height:window.innerHeight-($('.topics').height()+$('.filters').height()), marginTop: 3,paddingTop:0 }}><MatrixContainer></MatrixContainer></Grid>:null
            }
            {
              this.props.view=="Scatter Plot"?<Grid className="matrix" style={{height:window.innerHeight-($('.topics').height()+$('.filters').height()), marginTop: 3,paddingTop:0 }}><ScatterplotContainer></ScatterplotContainer></Grid>:null
            }
            {
              this.props.view=="Quartile"?<Grid className="matrix" style={{height:window.innerHeight-($('.topics').height()+$('.filters').height()), marginTop: 3,paddingTop:0 }}><QuartileContainer></QuartileContainer></Grid>:null
            }
          </Grid> : null}
          {this.props.original_data != null ?<SimilarityRootContainer></SimilarityRootContainer>:null}
      </Grid >
    )
  }
}
const maptstateToprop = (state) => {
  return {
    original_data: state.original_data,
    selected_month: state.selected_month,
    view: state.view,
    keywords_data: state.keywords_data,
  }
}
const mapdispatchToprop = (dispatch) => {
  return {
    Set_topic_month: (val) => dispatch({ type: "topic_month", value: val }),
    Set_original_data: (val) => dispatch({ type: "original_data", value: val }),
    Set_Months: (val) => dispatch({ type: "Months", value: val }),
    Set_FlairSentiments: (val) => dispatch({ type: "FlairSentiments", value: val }),
    Set_FlairSentimentScoreRange: (val) => dispatch({ type: "FlairSentimentScoreRange", value: val }),
    Set_scoresSubjectivityRange: (val) => dispatch({ type: "scoresSubjectivityRange", value: val }),
    Set_Selected_FlairSentimentScoreRange: (val) => dispatch({ type: "Selected_FlairSentimentScoreRange", value: val }),
    Set_Selected_scoresSubjectivityRange: (val) => dispatch({ type: "Selected_scoresSubjectivityRange", value: val }),
    Set_keyword_freq_arr_by_topic: (val) => dispatch({ type: "keyword_freq_arr_by_topic", value: val }),
    Set_keywords_data: (val) => dispatch({ type: "keywords_data", value: val }),
  }
}
export default connect(maptstateToprop, mapdispatchToprop)(App);
