import { MyLocationSharp } from '@material-ui/icons';
import * as d3 from 'd3';
export function getRange(data, attr) {
    var temp = []
    data.map(item => temp.push(item[attr]))
    return [d3.min(temp), d3.max(temp)]
}
export function createMatrixData(selected_data, Selected_FlairSentimentScoreRange, Selected_scoresSubjectivityRange, selected_sentiment, search_string) {
    if (search_string.length > 0) {
        var selected_data = selected_data.filter(item => item['Text'].match(search_string) != null)
    }
    //console.log(selected_data,search_string)
    var matrix_data = {}
    //console.log(selected_data,temp)
    selected_data.map(item => {
        // When sentiment is Any
        if (selected_sentiment == "Any") {
            //console.log(item["Topic"],matrix_data)
            if (item["FlairSentimentScore"] >= Selected_FlairSentimentScoreRange[0] && item["FlairSentimentScore"] <= Selected_FlairSentimentScoreRange[1] && item["SubjectivityScore"] >= Selected_scoresSubjectivityRange[0] && item["SubjectivityScore"] <= Selected_scoresSubjectivityRange[1]) {
                if (item["Topic"] in matrix_data) {
                    item["Top10Keywords"].map(keyword => {
                        if (keyword in matrix_data[item['Topic']]) {
                            if (item['Text2'].includes(keyword)) {
                                matrix_data[item['Topic']][keyword].push(item)
                            }
                        }
                        else {
                            matrix_data[item['Topic']][keyword] = []
                            if (item['Text2'].includes(keyword)) {
                                matrix_data[item['Topic']][keyword].push(item)
                            }
                        }
                    })
                } else {
                    matrix_data[item["Topic"]] = {}
                    item["Top10Keywords"].map(keyword => {
                        if (keyword in matrix_data[item['Topic']]) {
                            if (item['Text2'].includes(keyword)) {
                                matrix_data[item['Topic']][keyword].push(item)
                            }
                        }
                        else {
                            matrix_data[item['Topic']][keyword] = []
                            if (item['Text2'].includes(keyword)) {
                                matrix_data[item['Topic']][keyword].push(item)
                            }
                        }
                    })
                }
            }
        }
        // When sentiment is not Any
        else {
            if (item["FlairSentiment"] == selected_sentiment && item["FlairSentimentScore"] >= Selected_FlairSentimentScoreRange[0] && item["FlairSentimentScore"] <= Selected_FlairSentimentScoreRange[1] && item["SubjectivityScore"] >= Selected_scoresSubjectivityRange[0] && item["SubjectivityScore"] <= Selected_scoresSubjectivityRange[1]) {
                if (item["Topic"] in matrix_data) {
                    item["Top10Keywords"].map(keyword => {
                        if (keyword in matrix_data[item['Topic']]) {
                            if (item['Text2'].includes(keyword)) {
                                matrix_data[item['Topic']][keyword].push(item)
                            }
                        }
                        else {
                            matrix_data[item['Topic']][keyword] = []
                            if (item['Text2'].includes(keyword)) {
                                matrix_data[item['Topic']][keyword].push(item)
                            }
                        }
                    })
                } else { matrix_data[item["Topic"]] = {} }
            }
        }
    })
    return Object.entries(matrix_data)
}

export function createMatrixvis(data, topic_month, checked, max_keywords, space_for_topic_name, cellWidth, cellHeight, show_tweets, Set_show_tweets, selected_topics, maxTopicLength) { // Data contains the topics and keywords and selected_topics is the sorted topics
    // Total width for the matrix = space_for_topic_name + max_keywords * cellWidth   
    var number_of_circs = 65
    var circ_radius = 3
    var my_cx = circ_radius * 2.5
    var circs_per_line = parseInt(cellWidth / my_cx)
    // Define the div for the tooltip
    var div = d3.select("body").append("div").attr("class", "tooltip").style("opacity", 0);
    var container = d3.select(".svg_matrix_container").attr("height", data.length * cellHeight) // Parent Container
    data = topic_sorting(data, selected_topics)
    container.selectAll(".topics_container").data(data).join("g").attr("class", "topics_container").attr("x", 10).attr("y", 20) // group element for topic and keyword    
        .attr("add_keywords_cell", function (row_data, row_index) { // row data contains topic name and keyword object with keyword as key and data as value 
            // Keyword cell starts here
            d3.select(this).selectAll(".topic_name").data([row_data[0]]).join("foreignObject").attr("class", "topic_name").attr("x", 0).attr("y", (row_index * cellHeight))
                .attr("width", space_for_topic_name)
                .attr("height", cellHeight)
                .selectAll('.foreigndiv').data([0]).join("xhtml:div").attr('class','foreigndiv')
                .style("width", space_for_topic_name+"px")
                .style("height", cellHeight+'px')
                .html("<p>"+row_data[0] + " (" + topic_month[row_data[0]] + ")"+"</p>");

            var cell_data = Object.entries(row_data[1])  // svg element for each keyword
            cell_data = cell_data.slice(0, max_keywords)
            if (checked == 'FlairSentimentScore') { var color = d3.scaleLinear().domain([-1, 0, 1]).range(["#e41a1c", "white", "#4daf4a"]) }
            else { var color = d3.scaleLinear().domain([0, 1]).range(["#2166ac", "#b2182b"]) }
            cell_data.map(item => item[1].sort((a, b) => a[checked] - b[checked])) // Sort the data
            d3.select(this).selectAll(".cell_svg").data(cell_data, d => d[0]).join('svg').sort((a, b) => b[1].length - a[1].length).attr("class", "cell_svg").attr("x", (d, i) => (i * cellWidth) + space_for_topic_name).attr("y", (row_index * cellHeight)).attr("width", cellWidth - 1).attr("height", cellHeight - 1)
                .attr("add_line", function () {
                    //d3.select(this).selectAll("line").data([0]).join('line').attr("x1",0).attr("x2",cellWidth).attr("y1",18).attr("y2",18).attr("stroke","#d6d6d6")
                })
                .attr("add_cell_rect", function (temp_circle_data) {
                    d3.select(this).selectAll("#cell_rect").data([0]).join('rect').attr("class", () => { // This is useless
                        var myclassname = ""
                        show_tweets.map(item => { if (item[0] == temp_circle_data[0] & item[2] == row_data[0]) { myclassname = "cell_rect_selected" } })
                        return myclassname
                    }).attr("id", "cell_rect").attr("width", d3.select(this).attr("width")).attr("height", d3.select(this).attr("height")).attr('fill', "#e2e2e2").attr('stroke', "#cccccc").attr('stroke-width', 5)
                })
                .attr("add_cell_title", function (temp_circle_data) {
                    d3.select(this).selectAll(".cell_title").data([0]).join('text').attr("class", "cell_title").attr("x", cellWidth / 2).attr("y", 13)
                        .text(temp_circle_data[0]).attr("text-anchor", "middle").attr("font-size", 14).on('dblclick', () => d3.event.preventDefault())
                })
                .attr("add_circles", function (temp_circle_data) { // circle_data contains keyword as key and items as array
                    var circle_data = temp_circle_data[1].slice(0, number_of_circs)
                    d3.select(this).attr("lower", 0).attr("upper", number_of_circs).selectAll("circle").data(circle_data).join('circle')
                        .attr("r", circ_radius)
                        .attr("cx", (d, i) => my_cx * (i % circs_per_line) + 6)
                        .attr("cy", (d, i) => 23 + parseInt(i / circs_per_line) * 10)
                        .attr("fill", d => color(d[checked]))
                        .on("mouseover", function (d) {
                            //console.log(d)	
                            div.transition()
                                .duration(200)
                                .style("opacity", .9);
                            div.html(d["Text"])
                                .style("left", (d3.event.pageX - 5) + "px")
                                .style("top", (d3.event.pageY + 20) + "px");
                        })
                        .on("mouseout", function (d) {
                            div.transition()
                                .duration(500)
                                .style("opacity", 0);
                        });
                })
                .attr("add_prev_button", function (temp_circle_data) {
                    //var lower = parseInt(d3.select(this).attr("lower"))
                    //if(lower==0){return 0}
                    if (temp_circle_data[1].length <= number_of_circs) { } else {
                        d3.select(this).selectAll(".prev").data([0]).join('text').attr("font-size", 12)
                            .attr("x", 4).attr("y", 13).text("\uf104").attr('class', "fa prev make_cursor").on('click', function () {
                                if (parseInt(d3.select(this.parentNode).attr("lower")) < number_of_circs) {
                                    var lower = 0
                                    var upper = number_of_circs
                                }
                                else {
                                    var lower = parseInt(d3.select(this.parentNode).attr("lower")) - number_of_circs
                                    var upper = parseInt(d3.select(this.parentNode).attr("upper")) - number_of_circs
                                }
                                var circle_data = temp_circle_data[1].slice(lower, upper)
                                d3.select(this.parentNode).attr("lower", lower).attr("upper", upper).selectAll("circle").data(circle_data).join('circle')
                                    .attr("r", circ_radius)
                                    .attr("cx", (d, i) => my_cx * (i % circs_per_line) + 6)
                                    .attr("cy", (d, i) => 23 + parseInt(i / circs_per_line) * 10)
                                    .attr("fill", d => color(d[checked]))
                                    .on("mouseover", function (d) {
                                        div.transition()
                                            .duration(200)
                                            .style("opacity", .9);
                                        div.html(d["Text"])
                                            .style("left", (d3.event.pageX - 5) + "px")
                                            .style("top", (d3.event.pageY + 20) + "px");
                                    })
                                    .on("mouseout", function (d) {
                                        div.transition()
                                            .duration(500)
                                            .style("opacity", 0);
                                    });
                            })
                    }
                })
                .attr("add_next_button", function (temp_circle_data) {
                    if (temp_circle_data[1].length <= number_of_circs) { } else {
                        d3.select(this).selectAll(".next").data([0]).join('text').attr("font-size", 12)
                            .attr("x", cellWidth - 12).attr("y", 13).text("\uf105").attr('class', "fa next make_cursor").on('click', function () {
                                // Set max circs
                                if (parseInt(d3.select(this.parentNode).attr("upper")) < temp_circle_data[1].length) {
                                    var lower = parseInt(d3.select(this.parentNode).attr("lower")) + number_of_circs
                                    var upper = parseInt(d3.select(this.parentNode).attr("upper")) + number_of_circs
                                }
                                else {
                                    var lower = parseInt(d3.select(this.parentNode).attr("lower"))
                                    var upper = parseInt(d3.select(this.parentNode).attr("upper"))
                                }
                                var circle_data = temp_circle_data[1].slice(lower, upper)
                                d3.select(this.parentNode).attr("lower", lower).attr("upper", upper).selectAll("circle").data(circle_data).join('circle')
                                    .attr("r", circ_radius)
                                    .attr("cx", (d, i) => my_cx * (i % circs_per_line) + 6)
                                    .attr("cy", (d, i) => 23 + parseInt(i / circs_per_line) * 10)
                                    .attr("fill", d => color(d[checked]))
                                    .on("mouseover", function (d) {
                                        //console.log(d)	
                                        div.transition()
                                            .duration(200)
                                            .style("opacity", .9);
                                        div.html(d["Text"])
                                            .style("left", (d3.event.pageX - 5) + "px")
                                            .style("top", (d3.event.pageY + 20) + "px");
                                    })
                                    .on("mouseout", function (d) {
                                        div.transition()
                                            .duration(500)
                                            .style("opacity", 0);
                                    });
                            })
                    }
                })
                .on("dblclick", function (temp_circle_data) {
                    d3.selectAll(".tooltip").remove()
                    //console.log(row_data[0])
                    temp_circle_data = [...temp_circle_data, row_data[0]]
                    //d3.event.preventDefault();
                    d3.select(this).select('#cell_rect').classed("cell_rect_selected", () => {
                        if (d3.select(this).select('#cell_rect').classed("cell_rect_selected")) {
                            Set_show_tweets([])
                            return false
                        }
                        else {
                            //Set_show_tweets([...show_tweets, temp_circle_data])
                            Set_show_tweets([temp_circle_data])
                            return true
                        }
                    })

                })
            // Keyword cell ends here

        })

}


export function createScatterplot(data, topic_month, checked, max_keywords, space_for_topic_name, cellWidth, cellHeight, show_tweets) { // Data contains the topics and keywords
    // Total width for the matrix = space_for_topic_name + max_keywords * cellWidth   
    // Define the div for the tooltip
    var div = d3.select("body").append("div").attr("class", "tooltip").style("opacity", 0);
    var container = d3.select(".svg_matrix_container").attr("height", data.length * cellHeight) // Parent Container
    container.selectAll(".topics").data(data).join("g").attr("class", "topics").attr("x", 10).attr("y", 20) // group element for topic and keyword
        .attr("add_keywords_cell", function (row_data, row_index) { // row data contains topic name and keyword object with keyword as key and data as value 
            // Keyword cell starts here
            d3.select(this).selectAll(".topic_name").data([row_data[0]]).join("foreignObject").attr("class", "topic_name").attr("x", 0).attr("y", (row_index * cellHeight))
                .attr("width", space_for_topic_name)
                .attr("height", cellHeight)
                .selectAll('.foreigndiv').data([0]).join("xhtml:div").attr('class','foreigndiv')
                .style("width", space_for_topic_name+"px")
                .style("height", cellHeight+'px')
                .html("<p>"+row_data[0] + " (" + topic_month[row_data[0]] + ")"+"</p>");
            
            var cell_data = Object.entries(row_data[1])  // svg element for each keyword
            cell_data = cell_data.slice(0, max_keywords)
            var color = d3.scaleLinear().domain([-1, 0, 1]).range(["#e41a1c", "white", "#4daf4a"]);

            d3.select(this).selectAll(".cell_svg").data(cell_data, d => d[0]).join('svg').sort((a, b) => b[1].length - a[1].length).attr("class", "cell_svg").attr("x", (d, i) => (i * cellWidth) + space_for_topic_name).attr("y", (row_index * cellHeight)).attr("width", cellWidth - 1).attr("height", cellHeight - 1)
                .attr("add_line", function () {
                    //d3.select(this).selectAll("line").data([0]).join('line').attr("x1",0).attr("x2",cellWidth).attr("y1",18).attr("y2",18).attr("stroke","#d6d6d6")
                })
                .attr("add_cell_rect", function (temp_circle_data) {
                    d3.select(this).selectAll("#cell_rect").data([0]).join('rect').attr("class", () => { // This is useless
                        var myclassname = ""
                        show_tweets.map(item => { if (item[0] == temp_circle_data[0] & item[2] == row_data[0]) { myclassname = "cell_rect_selected" } })
                        return myclassname
                    }).attr("id", "cell_rect").attr("width", d3.select(this).attr("width")).attr("height", d3.select(this).attr("height")).attr('fill', "#e2e2e2").attr('stroke', "white").attr('stroke-width', 5)
                })
                .attr("add_cell_title", function (temp_circle_data) {
                    d3.select(this).selectAll(".cell_title").data([0]).join('text').attr("class", "cell_title").attr("x", cellWidth / 2).attr("y", 13)
                        .text(temp_circle_data[0]).attr("text-anchor", "middle").attr("font-size", 14).on('dblclick', () => d3.event.preventDefault())
                })
                .attr("add_circles", function (temp_circle_data) { // circle_data contains keyword as key and items as array
                    var circle_data = temp_circle_data[1]
                    var x_data = circle_data.map(item => item['SubjectivityScore'])
                    var y_data = circle_data.map(item => item['FlairSentimentScore'])
                    //console.log(circle_data)
                    var margin = { top: 23, right: 14, bottom: 40, left: 30, circ_radius: 3 },
                        width = cellWidth - margin.left - margin.right,
                        height = cellHeight - margin.top - margin.bottom;
                    var svg = d3.select(this).selectAll("g").data([0]).join("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")")
                    //.attr("width", width + margin.left + margin.right).attr("height", height + margin.top + margin.bottom) //----------------------------- Get svg x scale and y scale
                    var x = d3.scaleLinear().domain([d3.min(x_data), d3.max(x_data)]).range([margin.circ_radius * 2, width])
                    //var y = d3.scaleLinear().domain([d3.min(y_data), d3.max(y_data)]).range([height, 0]).nice();
                    var y = d3.scaleLinear().domain([-1, 1]).range([height, 0]).nice();
                    var temp_y = cellHeight - margin.bottom + 5
                    var xAxis = d3.select(this).selectAll(".myXaxis").data([0]).join('g').attr('class', 'myXaxis').attr("transform", "translate(" + margin.left + "," + temp_y + ")") //-------------------------------------------------- X axis
                    xAxis.call(d3.axisBottom(x).ticks(2))

                    var yAxis = d3.select(this).selectAll(".myYaxis").data([0]).join('g').attr('class', 'myYaxis').attr("transform", "translate(" + margin.left + "," + margin.top + ")") //--------------------------------------------------Y axis
                    yAxis.call(d3.axisLeft(y).ticks(2));

                    var bars = svg.selectAll("circle").data(circle_data, d => d["Text"]) //------------------------------------------------------------------------------ Drawing starts here
                    bars.join('circle').attr("cx", d => x(d["SubjectivityScore"]))
                        .attr("cy", function (d) { return y(d["FlairSentimentScore"]) })
                        .attr("r", margin.circ_radius)
                        .attr("fill", d => color(d["FlairSentimentScore"]))

                })
                .attr("add_title", function () {
                    d3.select(this).selectAll(".x_label").data([0]).join('text').attr('class', "x_label").attr("x", 30).attr("y", 83).text("Subjectivity").attr('font-size', 10)
                })
            //d3.selectAll('.domain').remove()
        })

}

export function createQuartiles(data, topic_month, checked, max_keywords, space_for_topic_name, cellWidth, cellHeight, show_tweets) { // Data contains the topics and keywords
    var container = d3.select(".svg_matrix_container").attr("height", data.length * cellHeight) // Parent Container
    container.selectAll(".topics").data(data).join("g").attr("class", "topics").attr("x", 10).attr("y", 20) // group element for topic and keyword
        .attr("add_keywords_cell", function (row_data, row_index) { // row data contains topic name and keyword object with keyword as key and data as value 
            // Keyword cell starts here
            d3.select(this).selectAll(".topic_name").data([row_data[0]]).join("foreignObject").attr("class", "topic_name").attr("x", 0).attr("y", (row_index * cellHeight))
            .attr("width", space_for_topic_name)
            .attr("height", cellHeight)
            .selectAll('.foreigndiv').data([0]).join("xhtml:div").attr('class','foreigndiv')
            .style("width", space_for_topic_name+"px")
            .style("height", cellHeight+'px')
            .html("<p>"+row_data[0] + " (" + topic_month[row_data[0]] + ")"+"</p>");

            var cell_data = Object.entries(row_data[1])  // svg element for each keyword
            cell_data = cell_data.slice(0, max_keywords)
            //var color = d3.scaleLinear().domain([-1, 0, 1]).range(["#e41a1c", "white", "#4daf4a"]);
            if (checked == 'FlairSentimentScore') { var color = d3.scaleLinear().domain([0, 1]).range(["#2166ac", "#b2182b"]) }
            else { var color = d3.scaleLinear().domain([-1, 0, 1]).range(["#e41a1c", "white", "#4daf4a"]) }
            d3.select(this).selectAll(".cell_svg").data(cell_data, d => d[0]).join('svg').sort((a, b) => b[1].length - a[1].length).attr("class", "cell_svg").attr("x", (d, i) => (i * cellWidth) + space_for_topic_name).attr("y", (row_index * cellHeight))
                .attr("width", cellWidth - 1).attr("height", cellHeight - 1)
                .attr("add_line", function () {
                    //d3.select(this).selectAll("line").data([0]).join('line').attr("x1",0).attr("x2",cellWidth).attr("y1",18).attr("y2",18).attr("stroke","#d6d6d6")
                })
                .attr("add_cell_rect", function (temp_circle_data) {
                    d3.select(this).selectAll("#cell_rect").data([0]).join('rect').attr("class", () => { // This is useless
                        var myclassname = ""
                        show_tweets.map(item => { if (item[0] == temp_circle_data[0] & item[2] == row_data[0]) { myclassname = "cell_rect_selected" } })
                        return myclassname
                    }).attr("id", "cell_rect").attr("width", d3.select(this).attr("width")).attr("height", d3.select(this).attr("height")).attr('fill', "#e2e2e2").attr('stroke', "white").attr('stroke-width', 5)
                })
                .attr("add_cell_title", function (temp_circle_data) {
                    d3.select(this).selectAll(".cell_title").data([0]).join('text').attr("class", "cell_title").attr("x", cellWidth / 2).attr("y", 13)
                        .text(temp_circle_data[0]).attr("text-anchor", "middle").attr("font-size", 14).on('dblclick', () => d3.event.preventDefault())
                })
                .attr("add_circles", function (temp_circle_data) { // circle_data contains keyword as key and items as array
                    var circle_data = temp_circle_data[1]
                    //console.log(circle_data)
                    var margin = { top: 23, right: 14, bottom: 0, left: 20, circ_radius: 3 }

                    var width = cellWidth - margin.left - margin.right,
                        height = cellHeight - margin.top - margin.bottom;
                    console.log("cellHeight", cellHeight, height)
                    var h_start = margin.top, h_end = cellHeight - margin.bottom, quartile = (height - h_start) / 4;
                    var w_start = margin.left, w_end = cellWidth - margin.right;



                    d3.select(this).selectAll(".xlines").data([1, 2, 3]).join('line').attr('class', 'xlines').attr("x1", w_start).attr("x2", w_end + margin.circ_radius)
                        .attr("y1", d => d * quartile - margin.circ_radius + margin.top)
                        .attr("y2", d => d * quartile - margin.circ_radius + margin.top).attr("stroke", "#afafaf")
                    d3.select(this).selectAll(".ylabels").data([[1, "++"], [2, "+"], [3, "-"], [4, "--"]]).join('text').attr('class', 'ylabels')
                        .attr("y", d => d[0] * quartile + margin.top - quartile).attr("x", margin.left - 3)// change x and text-anchor together
                        .attr('text-anchor', 'end').attr('dominant-baseline', 'hanging')
                        .text(d => d[1]).attr("font-size", 10).attr('fill', "#636363")
                    // 
                    d3.select(this).selectAll(".yline").data([0]).join('line').attr('class', 'yline').attr("x1", margin.left).attr("x2", margin.left)
                        .attr("y1", h_start).attr("y2", height).attr('stroke', '#afafaf')
                    var circ_container = d3.select(this).selectAll("g").data([0]).join("g").attr("transform", "translate(" + margin.left + "," + 0 + ")")
                    if (checked == 'FlairSentimentScore') {
                        var checked2 = 'SubjectivityScore'
                        var x = d3.scaleLinear().domain([0, 1]).range([margin.circ_radius * 2, width])
                        var y = d3.scaleLinear().domain([-1, 1]).range([height - margin.circ_radius, h_start + margin.circ_radius]).nice();
                    }
                    else {
                        var checked2 = 'FlairSentimentScore'
                        var x = d3.scaleLinear().domain([-1, 1]).range([margin.circ_radius * 2, width])
                        var y = d3.scaleLinear().domain([0, 1]).range([height - margin.circ_radius, h_start + margin.circ_radius]).nice();
                    }

                    circ_container.selectAll("circle").data(circle_data, d => d["Text"]).join('circle').attr("cx", d => x(d[checked2]))
                        .attr("cy", function (d) { return y(d[checked]) })
                        .attr("r", margin.circ_radius)
                        .attr("fill", d => color(d[checked2]))

                    d3.select(this).selectAll(".x_label").data([0]).join('text').attr('class', "x_label").attr("x", 30).attr("y", height + 10).text(checked == "FlairSentimentScore" ? "Sentiment" : "Subjectivity").attr('font-size', 10)

                })

        })

}

function topic_sorting(items, sorting) {
    var result = []
    sorting.forEach(function (key) {
        var found = false;
        items = items.filter(function (item) {
            if (!found && item[0] == key) {
                result.push(item);
                found = true;
                return false;
            } else
                return true;
        })
    })
    return result
}
