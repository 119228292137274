import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as d3 from 'd3';
import Grid from '@mui/material/Grid';
import * as $ from 'jquery'

class SimView extends Component {
    constructor(props) {
        super(props)
        this.state = { 'sim_result_topic': [] }
    }
    componentDidMount() {
        this.setState({"a":10})
    }
    componentDidUpdate() {
        Object.entries(this.props.sim_result_topic).map((doc_arr,doc_index)=>this.makeSim(doc_arr[0],doc_arr[1],doc_index)) // doc_arr[0] contains the text,  doc_arr[1] contains the array of similarity result)
        //https://observablehq.com/@ben-tanen/a-tutorial-to-using-d3-force-from-someone-who-just-learned-ho#xy_sect            
    }
    makeSim=(doc_text,sim_result,doc_index)=>{
        var width = $("#"+this.props.topic+doc_index).width(), height = $("#"+this.props.topic+doc_index).height()
        console.log(sim_result,width,height)
        var myScale=d3.scaleLinear().domain(d3.extent(sim_result.map(item=>item[1]))).range([width/4,0])
        //var myScale=d3.scaleLinear().domain([0,1]).range([width/4,0])
        var nodes = sim_result.map((item, index) => {
            var temp = {}
            temp['id'] = "id" + index
            temp['text'] = item[0]
            temp['score'] = item[1]
            return temp
        })
        nodes = [...nodes, { "id": "parent", 'text': doc_text }]
        var links = sim_result.map((item, index) => {
            var temp = {}
            temp['source'] = "id" + index
            temp['target'] = "parent"
            temp['strength'] =myScale(item[1])
            return temp
        })
        var svg = d3.select("#"+this.props.topic+doc_index).attr('width', width).attr('height', height)
        // simulation setup with all forces
        //var linkForce = d3.forceLink().id(link=> link.id).strength(link=> link.strength)
        var simulation = d3.forceSimulation()
            .force('link', d3.forceLink().id(link => link.id).distance(link => link.strength))
            .force('charge', d3.forceManyBody().strength(-120)) // https://github.com/d3/d3-force#many-body
            .force('center', d3.forceCenter(width / 2, height / 2))
        var linkElements = svg.selectAll(".links").data([0]).join('g').attr("class", "links").selectAll(".mylines").data(links).join("line").attr('class', 'mylines').attr("stroke-width", 1).attr("stroke", "rgba(50, 50, 50, 0.2)")
        var nodeElements = svg.selectAll(".nodes").data([0]).join('g').attr("class", "nodes").selectAll("circle").data(nodes).join("circle").attr("r", 6).attr("fill", d=>d['id']=='parent'?"green":"grey")
        simulation.nodes(nodes).on('tick', () => {
            nodeElements.attr('cx', node => node.x).attr('cy', node => node.y).on('click', (d) => alert(d['text']))
            linkElements.attr('x1', link => link.source.x).attr('y1', link => link.source.y).attr('x2', link => link.target.x).attr('y2', link => link.target.y)
        })
        simulation.force("link").links(links)
        
    }
    render() {
        return (
            <div>
            <p style={{padding:"0px 10px"}}> {this.props.topic_original} </p>
            {Object.entries(this.props.sim_result_topic).map((doc_arr,doc_index)=><svg id={this.props.topic+doc_index} style={{width:"100%",height:"50%"}}> </svg>)}
            </div>
        )
    }
}
const maptstateToprop = (state) => {
    return {
        sim_result: state.sim_result,
        topic_month: state.topic_month,
    }
}
const mapdispatchToprop = (dispatch) => {
    return {
        Set_RootContainerOpen: (val) => dispatch({ type: "RootContainerOpen", value: val }),
    }
}
export default connect(maptstateToprop, mapdispatchToprop)(SimView);


