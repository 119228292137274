import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { connect } from "react-redux";
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import SimView from './03SimView';
const style = {
    position: 'absolute',
    top: '5%',
    left: 15,
    width: window.innerWidth-97,
    height: "85%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
function RootContainer(props) {
    var table_data=props.original_data['September'].slice(10,200)
    const handleClose = () => props.Set_RootContainerOpen(false);
    return (
        <div>
            <Modal
                open={props.RootContainerOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h3 style={{marginLeft:"45%"}}> September </h3>
                <Button style={{marginLeft:"95%",marginTop:0,color:"red"}} onClick={()=>props.Set_RootContainerOpen(false)}>Close</Button>
                    <Grid container spacing={2}>
                        <Grid item xs style={{backgroundColor:"white"}}>
                            {props.original_data!=null?<SimView original_data={props.original_data}></SimView>:null}
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}

const maptstateToprop = (state) => {
    return {
        RootContainerOpen: state.RootContainerOpen,
        original_data: state.original_data,
    }
}
const mapdispatchToprop = (dispatch) => {
    return {
        Set_sim_result: (val) => dispatch({ type: "sim_result", value: val }),
        Set_RootContainerOpen: (val) => dispatch({ type: "RootContainerOpen", value: val }),
    }
}
export default connect(maptstateToprop, mapdispatchToprop)(RootContainer);

