import React, { Component } from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import ShowTweets from "./ShowTweets/ShowTweets"
import * as $ from 'jquery';
import * as algo1 from "../../algo1"
export class Matrix extends Component {
  constructor(props) {
    super(props)
    this.state = { selected_data: null }
  }
  componentDidMount() {
    this.setState({ a: 10 })
  }
  componentDidUpdate() {
    var filtered_selected_data = this.props.selected_data.filter(item => this.props.selected_topics.includes(item["Topic"])) // Filter the data by topics
    var matrix_data = algo1.createMatrixData(filtered_selected_data, this.props.Selected_FlairSentimentScoreRange, this.props.Selected_scoresSubjectivityRange, this.props.selected_sentiment,this.props.search_string)
    //console.log(this.props.selected_data)
    algo1.createQuartiles(matrix_data,this.props.topic_month,this.props.checked,this.props.matrix_config.max_keywords, this.props.matrix_config.space_for_topic_name, this.props.matrix_config.cellWidth, this.props.matrix_config.cellHeight,this.props.show_tweets,this.props.Set_show_tweets)
  }

  render() {
    var matrix_width=this.props.matrix_config.space_for_topic_name +(this.props.matrix_config.max_keywords*this.props.matrix_config.cellWidth)
    return (
      <Grid container direction="row" justify="flex-start" alignItems="flex-start">
        <Grid item xs style={{minWidth:matrix_width,height:window.innerHeight-($('.topics').height()+$('.filters').height()),width:window.innerWidth, overflow:"auto",border:"0px solid #e2e2e2"}}>
          <svg className="svg_matrix_container" style={{ width: matrix_width }}></svg>
        </Grid>
      </Grid >
    )
  }
}
const maptstateToprop = (state) => {
  return {
    selected_data:state.selected_data,
    Selected_FlairSentimentScoreRange:state.Selected_FlairSentimentScoreRange,
    Selected_scoresSubjectivityRange:state.Selected_scoresSubjectivityRange,
    selected_sentiment:state.selected_sentiment,
    selected_topics: state.selected_topics,
    matrix_config: state.matrix_config,
    show_tweets:state.show_tweets,
    topic_month:state.topic_month,
    checked:state.checked,
    search_string:state.search_string
  }
}
const mapdispatchToprop = (dispatch) => {
  return {
    Set_show_tweets: (val) => dispatch({ type: "show_tweets", value: val }),
  }
}
export default connect(maptstateToprop, mapdispatchToprop)(Matrix);
