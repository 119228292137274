import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import theme from "./theme";
import { ThemeProvider } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  MuiToolbar_root: {
    backgroundColor: "#f4f4f4",
    color: "black",
  }
}));
export function FilterContainer(props) {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
    <Grid className="FilterContainer" container style={{ backgroundColor: "#f4f4f4", width: window.innerWidth, height: 35 }} direction="row" justify="center" alignItems="center">
      Keywords
    </Grid>
    </ThemeProvider>
  );
}

const maptstateToprop = (state) => {
  return {
    original_data: state.original_data,
    Months: state.Months,
    selected_month:state.selected_month,
    selected_sentiment:state.selected_sentiment,
    FlairSentiments:state.FlairSentiments,
  }
}
const mapdispatchToprop = (dispatch) => {
  return {
    Set_selected_sentiment: (val) => dispatch({ type: "selected_sentiment", value: val }),
    Set_selected_month: (val) => dispatch({ type: "selected_month", value: val }),
    Set_FlairSentimentScoreRange: (val) => dispatch({ type: "FlairSentimentScoreRange", value: val }),
    Set_scoresSubjectivityRange: (val) => dispatch({ type: "scoresSubjectivityRange", value: val }),
  }
}
export default connect(maptstateToprop, mapdispatchToprop)(FilterContainer);
