import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import Fade from '@material-ui/core/Fade';
import { connect } from "react-redux";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
function FadeMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //------------For list and chckbox
  const [checked, setChecked] = React.useState([0]);
  const [selected_states, setSelected_states] = React.useState([]);
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  return (
    <div style={{ borderRight: "1px dashed #eaeaea",maxWidth:150}}>
      <Button aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick} style={{textTransform:"none",height:30,marginTop:3,padding:0}}>
        <ArrowDropDownIcon></ArrowDropDownIcon>
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <div className="CheckboxContainer">
          <List>
            {['sentiment','subjectivity'].map((value) => {
              const labelId = `checkbox-list-label-${value}`;
              return (
                <ListItem key={value} role={undefined} onClick={handleToggle(value)}>
                  <Checkbox
                    checked={props.checkedTest==value}
                    edge="start"
                    tabIndex={-1}
                    value={value}
                    onChange={(event, value) => {
                      //console.log(event.target.value)
                      props.Set_checked(event.target.value);


                    }
                    }
                  />
                  <p className="list_item_label" id={labelId}>{value}</p>
                </ListItem>
              );
            })}
          </List>
        </div>
      </Menu>
    </div>
  );
}
const maptstateToprop = (state) => {
  return {
    checkedTest:state.checked,
  }
}
const mapdispatchToprop = (dispatch) => {
  return {
    Set_checked: (val) => dispatch({ type: "checked", value: val }),
  }
}
export default connect(maptstateToprop, mapdispatchToprop)(FadeMenu);
//https://material-ui.com/components/menus/