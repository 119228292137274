import { createMuiTheme } from '@material-ui/core/styles';
import { grey, deepPurple, amber } from '@material-ui/core/colors';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: deepPurple[500],
    },
    secondary: {
      main: amber[500],
      contrastText: deepPurple[900],
    },
  },
});

theme.props = {
  MuiInputLabel: {
    shrink: true,
  },
  MuiInput: {
    disableUnderline: false,
  },
  MuiTooltip: {
    arrow: true,
  },
};

theme.overrides = {
  MuiInput: {
    root: {
      minHeight:20,
      height:25,
      minWidth:60,
      width:110,
      fontSize:16,
      top: theme.spacing(0),
      marginTop:0,
      border: `none`,
      outline: `1px solid transparent`,
      paddingLeft: 5,
      marginRight: 5,
      '&$focused': {
        border: `none`,
        outline: `none`,
      },
    },
  },
};

export default theme;
