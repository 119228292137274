import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import FlairSentimentDropdown from './FlairSentimentDropdown/FlairSentimentDropdown'
import FlairSentimentScoreSlider from "./FlairSentimentScoreSlider/FlairSentimentScoreSlider"
import SubjectivitySlider from "./SubjectivitySlider/SubjectivitySlider"
import Months from "./MonthsDropdown/Months"
import theme from "./theme";
import { ThemeProvider } from "@material-ui/core/styles";
import FilterDropDown from "./FilterDropDown/FilterDropDown"
import RadioDropDown from "./FilterDropDown/RadioDropDown"
import SearchBar from "./SearchBar/SearchBar"
import View from "./View/View"
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(0),
  },
  MuiToolbar_root: {
    backgroundColor: "#f4f4f4",
    color: "black",
  }
}));
export function FilterContainer(props) {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Grid className="FilterContainer" container style={{ backgroundColor: "#f4f4f4", width: window.innerWidth, height: 35 }} direction="row" justify="flex-start" alignItems="center">
        <Grid className="view" container style={{ marginLeft: 5, backgroundColor: "#f4f4f4", width: 210, margin: 0, marginTop: -2 }} direction="row" justify="center" alignItems="baseline">
          <SearchBar Set_search_string={props.Set_search_string}></SearchBar>
        </Grid>
        <p style={{ margin: 0, marginLeft: 40, marginTop: -5, marginRight: 0, padding: 0, fontWeight: "bold", fontSize: 18 }}>Filters:</p>
        <Grid className="view" container style={{ backgroundColor: "#f4f4f4", width: 190, marginTop: -2 }} direction="row" justify="center" alignItems="baseline">
          <p style={{ margin: 0, padding: 0, fontWeight: "bold" }} >View:</p><View FlairSentiments={props.FlairSentiments} Set_view={props.Set_view} view={props.view} ></View>
        </Grid>

        <Grid className="FlairSentimentDropdown" container style={{ backgroundColor: "#f4f4f4", width: 210, marginTop: -2 }} direction="row" justify="center" alignItems="baseline">
          <p style={{ margin: 0, padding: 0, fontWeight: "bold" }} >Sentiment:</p><FlairSentimentDropdown FlairSentiments={props.FlairSentiments} Set_selected_sentiment={props.Set_selected_sentiment} selected_sentiment={props.selected_sentiment} ></FlairSentimentDropdown>
        </Grid>

        <Grid className="SubjectivitySlider" container style={{ backgroundColor: "#f4f4f4", width: 230, marginTop: -3, marginLeft: 5 }} direction="row" justify="center" alignItems="center">
          <p style={{ margin: 0, padding: 0, fontWeight: "bold", marginRight: 3 }} >Subjectivity Score:</p><SubjectivitySlider></SubjectivitySlider>
        </Grid>
        {/*
        <Grid className="FlairSentimentScoreSlider" container style={{ backgroundColor: "#f4f4f4", width: 250, marginLeft: 20, marginTop: -3 }} direction="row" justify="center" alignItems="center">
          <p style={{ margin: 0, padding: 0, fontWeight: "bold", marginRight: 3 }} >Flair Sentiment Score:</p><FlairSentimentScoreSlider></FlairSentimentScoreSlider>
        </Grid>
        */}
        {props.view != "Scatter Plot" ? <Grid className="FilterDropDown" container style={{ backgroundColor: "#f4f4f4", width: 150, marginLeft: -10, marginTop: 0 }} direction="row" justify="center" alignItems="center">
          <RadioDropDown view={props.view}></RadioDropDown>
        </Grid> : null
        }
        <Grid className="SubjectivitySlider" container style={{ backgroundColor: "#f4f4f4", width: 230, marginTop: -3, marginLeft: 5 }} direction="row" justify="center" alignItems="center">
          <Button onClick={() => {
            props.Set_RootContainerOpen(true)
          }}>Similarity Analysis</Button>
        </Grid>
      </Grid>
    </ThemeProvider >
  );
}

const maptstateToprop = (state) => {
  return {
    original_data: state.original_data,
    Months: state.Months,
    selected_month: state.selected_month,
    selected_sentiment: state.selected_sentiment,
    FlairSentiments: state.FlairSentiments,
    view: state.view,
  }
}
const mapdispatchToprop = (dispatch) => {
  return {
    Set_selected_sentiment: (val) => dispatch({ type: "selected_sentiment", value: val }),
    Set_selected_month: (val) => dispatch({ type: "selected_month", value: val }),
    Set_FlairSentimentScoreRange: (val) => dispatch({ type: "FlairSentimentScoreRange", value: val }),
    Set_scoresSubjectivityRange: (val) => dispatch({ type: "scoresSubjectivityRange", value: val }),
    Set_view: (val) => dispatch({ type: "view", value: val }),
    Set_search_string: (val) => dispatch({ type: "search_string", value: val }),
    Set_RootContainerOpen: (val) => dispatch({ type: "RootContainerOpen", value: val }),
  }
}
export default connect(maptstateToprop, mapdispatchToprop)(FilterContainer);
