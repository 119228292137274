import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Grid from "@material-ui/core/Grid"
import { connect } from "react-redux";
const useStyles = makeStyles({
  root: {
    width: 200,
  },
});

function valuetext(value) {
  return `${value}°C`;
}
function FlairSentimentScoreSlider(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.FlairSentimentScoreRange);

  const handleChange = (event, newValue) => {
    //props.Set_Selected_FlairSentimentScoreRange(newValue)
    setValue(newValue);
  };
  const commitChange = (event, newValue) => {
    props.Set_Selected_FlairSentimentScoreRange(newValue)
  };
  return (
    <Grid container style={{width:70,marginTop:4,marginLeft:5}} direction="column" justify="flex-start" alignItems="flex-start">
      <Slider
        value={value}
        onChange={handleChange}
        onChangeCommitted={commitChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        getAriaValueText={valuetext}
        step={0.01}
        min={props.FlairSentimentScoreRange[0]}
        max={props.FlairSentimentScoreRange[1]}
      />
    </Grid>
  );
}
const maptstateToprop = (state) => {
  return {
    FlairSentimentScoreRange: state.FlairSentimentScoreRange,
  }
}
const mapdispatchToprop = (dispatch) => {
  return {
    Set_Selected_FlairSentimentScoreRange: (val) => dispatch({ type: "Selected_FlairSentimentScoreRange", value: val }),
  }
}
export default connect(maptstateToprop, mapdispatchToprop)(FlairSentimentScoreSlider);