import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import theme from "./theme";
import { ThemeProvider } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 100,
  },
}));
export default function SimpleSelect(props) {
  const classes = useStyles();
  const [view, Set_view] = React.useState(props.view);
  const handleChange = (event) => {
    props.Set_view(event.target.value)
    Set_view(event.target.value);
  };
  return (
    <ThemeProvider theme={theme}>
     <Grid container style={{width:100,marginLeft:0,marginTop:0}} direction="column" justify="flex-start" alignItems="flex-start">
      <FormControl className={classes.formControl}>
        <Select labelId="demo-simple-select-label" id="demo-simple-select" value={view} onChange={handleChange}>
          {
          ["Default","Scatter Plot","Quartile"].map(item=><MenuItem value={item}>{item}</MenuItem>)
          }
        </Select>
      </FormControl>
    </Grid>
    </ThemeProvider>
  );
}
