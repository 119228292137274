import React, { Component } from 'react'
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import theme from "./theme";
import { ThemeProvider } from "@material-ui/core/styles";
import "../styles.css"
import Months from "./MonthsDropdown/Months"

export class FilterContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { selected_data: null }
  }
  componentDidMount() {
    this.setState({ a: 10 })
  }
  componentDidUpdate() {

  }
  render() {
    var topics_by_month = {}
    this.props.keyword_freq_arr_by_topic.map(item => {
      if (item['Month'] in topics_by_month) { topics_by_month[item['Month']].push(item['Topic']) }
      else { topics_by_month[item['Month']] = []; topics_by_month[item['Month']].push(item['Topic']) }
    })
    var topics_by_month_arr = []
    this.props.selected_months.map(item1 => {
      Object.entries(topics_by_month).map(item2 => {
        if (item2[0] == item1) {
          topics_by_month_arr.push(item2)
        }
      })
    })
    return (
      <ThemeProvider theme={theme}>
        <Grid className="FilterContainer" container style={{ backgroundColor: "#f4f4f4", width: window.innerWidth }} direction="row" justify="flex-start" alignItems="flex-start">
          <Grid item style={{marginTop:0}}><Months></Months></Grid>
          {
            topics_by_month_arr.map(item => {
              return <Grid item style={{ width: (window.innerWidth - 165) / topics_by_month_arr.length, borderLeft:"0px solid #c6c6c6"}}>
                <p className="tag_keyword">{item[0]}</p>
                {item[1].map(item1 => <p className={this.props.selected_topics.includes(item1) ? "all_chars selected_topic" : "all_chars"}
                  onClick={() => {
                    var temp_selected_topics = [...this.props.selected_topics]
                    //------Set selected topics start here
                    if (temp_selected_topics.includes(item1)) { temp_selected_topics = temp_selected_topics.filter(item => item != item1); this.props.Set_selected_topics(temp_selected_topics) }
                    else { temp_selected_topics = [...temp_selected_topics, item1]; this.props.Set_selected_topics(temp_selected_topics) }
                    //------Set selected topics ends here
                  }}
                >{item1}</p>)}
              </Grid>
            })
          }
        </Grid>
      </ThemeProvider>
    )
  }
}

const maptstateToprop = (state) => {
  return {
    keyword_freq_arr_by_topic: state.keyword_freq_arr_by_topic,
    selected_months: state.selected_months,
    selected_topics: state.selected_topics,
  }
}
const mapdispatchToprop = (dispatch) => {
  return {
    Set_selected_topics: (val) => dispatch({ type: "selected_topics", value: val }),
  }
}
export default connect(maptstateToprop, mapdispatchToprop)(FilterContainer);
