import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Mark from "mark.js"
import * as $ from 'jquery';

const useStyles = makeStyles({
  table: {
    // minWidth: $(".ShowTweetsContainer").width()
  },
  cell_short: {
    paddingTop: 6,
    paddingBottom: 3,
    fontSize: "12px",
    maxWidth: $(".ShowTweetsContainer").width(),
    overflowWrap: "anywhere",
    //backgroundColor:"#fcfcfc",
  },
});
export default function SimpleTable(props) {
  const rows = props.show_tweets[1]
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(rows.length);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  useEffect(() => {
    var context = document.querySelector(".mytable");
    var instance = new Mark(context);
    instance.mark(props.show_tweets[0],{"acrossElements":true});
  });
  return (
    <div>
      <div className="keyword_title" style={{ position: "sticky", top: "0", backgroundColor: "#E2E2E2", marginTop: -5, height: 30, display: "flex", alignItems: "center", justifyContent: "center" }}><p style={{ margin: 0, marginBottom: 0, textTransform: "capitalize", fontWeight: "bold" }}>{props.show_tweets[0]}</p></div>
      {
        /*
        <TablePagination className="table_pagination" style={{backgroundColor:"#f2f2f2"}}
          labelRowsPerPage="Tweets per page"
          rowsPerPageOptions={[3,6,12,24,rows.length]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        */
      }
      <TableContainer component="div" style={{ maxHeight: $(".ShowTweetsContainer").height() - ($(".table_pagination").height() + $(".keyword_title").height()), maxWidth: $(".ShowTweetsContainer").width(), width: $(".ShowTweetsContainer").width() }}>
        <Table className="mytable" aria-label="simple table" >
          {
            /*
            <TableHead>
              <TableRow>
                <TableCell align="center">Text</TableCell>
                {
                  // <TableCell align="right" width={50}>FlairSentimentScore</TableCell>
                  // <TableCell align="right" >SubjectivityScore</TableCell>
                }
              </TableRow>
            </TableHead>
            */
          }
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={Math.random()}>
                  <TableCell align="center" className={classes.cell_short}>
                    {row.Text}
                  </TableCell>
                  {
                    // <TableCell align="right" width={50}>{row.FlairSentimentScore}</TableCell>
                    // <TableCell align="right">{row.SubjectivityScore}</TableCell>
                  }
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

//https://www.youtube.com/watch?v=C5hGv5-A_Ik
//https://codesandbox.io/s/material-ui-table-cell-width-forked-hz8t4?file=/src/index.js:894-1002

//Expandable Table
//https://material-ui.com/components/tables/#table
//https://codesandbox.io/s/kbcee?file=/demo.js

//https://markjs.io/
