import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  MuiToolbar_root:{
      backgroundColor:"#f4f4f4",
      color:"black",
      borderBottom:"1px solid #c4c2c2",
      paddingLeft:7,
  }
}));

export default function DenseAppBar() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
        <Toolbar variant="dense" className={classes.MuiToolbar_root}>
          <Typography variant="h6" color="inherit">
            Content Moderation
          </Typography>
        </Toolbar>
    </div>
  );
}
