import * as d3 from 'd3';
let initialState = {
    keywords_data:null,
    matrix_config:{max_keywords:8,space_for_topic_name:150,cellWidth:100,cellHeight:90}, // Total width for the matrix = space_for_topic_name + max_keywords * cellWidth
    //matrix_config:{max_keywords:5,space_for_topic_name:205,cellWidth:95,cellHeight:70}, // Total width for the matrix = space_for_topic_name + max_keywords * cellWidth
    original_data: null, // This is the original data that we never change
    Months:[],
    selected_month:"April",
    selected_sentiment:"Any",
    FlairSentiments:[],
    FlairSentimentScoreRange:[],
    scoresSubjectivityRange:[],
    Selected_FlairSentimentScoreRange:[],
    Selected_scoresSubjectivityRange:[],
    keyword_freq_arr_by_topic:[],
    selected_months:["March","April","May","June","July","August","September"],
    selected_data:[],
    selected_topics:["Global Healthcare response",'Covid19 spreading and transmission','Life crisis','Infectious disease information','Disease outbreak Problems'],
    show_tweets:[],
    topic_month:{},
    checked:"FlairSentimentScore",
    view:"Default",
    search_string:"",
    maxTopicLength:null,
    RootContainerOpen:false,
    sim_result:[]
}

const reducer = (state = initialState, action) => {
    if (action.type === "sim_result") {
        //console.log(action.value)
        return { ...state, sim_result: action.value }
    }
    if (action.type === "RootContainerOpen") {
        return { ...state, RootContainerOpen: action.value }
    }
    if (action.type === "search_string") {
        //console.log("keywords_data",action.value)
        return { ...state, search_string: action.value }
    }
    if (action.type === "keywords_data") {
        //console.log("keywords_data",action.value)
        return { ...state, keywords_data: action.value }
    }
    if (action.type === "matrix_config") {
        //console.log("matrix_config",action.value)
        return { ...state, matrix_config: action.value }
    }
    if (action.type === "view") {
        d3.selectAll("#cell_rect").classed("cell_rect_selected",false)
        if(action.value=="Default"){return { ...state, view: action.value,matrix_config:{max_keywords:8,space_for_topic_name:150,cellWidth:100,cellHeight:90},show_tweets:[]}}
        if(action.value=="Scatter Plot"){return { ...state, view: action.value,matrix_config:{max_keywords:10,space_for_topic_name:150,cellWidth:100,cellHeight:90},show_tweets:[]}}
        if(action.value=="Quartile"){return { ...state, view: action.value,matrix_config:{max_keywords:10,space_for_topic_name:150,cellWidth:100,cellHeight:100},show_tweets:[]}}
}
    if (action.type === "checked") {
        //console.log("checked",action.value)
        return { ...state, checked: action.value }
    }
    if (action.type === "topic_month") {
        //console.log("topic_month",action.value)
        return { ...state, topic_month: action.value }
    }
    if (action.type === "show_tweets") {
        //if(state.show_tweets.includes(action.value)){return { ...state, show_tweets: [] }}
        //console.log(action.value)
        return { ...state, show_tweets: action.value }
    }
    if (action.type === "selected_topics") {
        var maxTopicLength=d3.max([...action.value].map(item=>item.length))
        var matrix_config={...state.matrix_config}
        //console.log(maxTopicLength,maxTopicLength*9+10)
        //matrix_config['space_for_topic_name']=(maxTopicLength*9)+10
        return { ...state, selected_topics: action.value,matrix_config:matrix_config }
    }
    if (action.type === "selected_data") {
        //console.log("selected_data",action.value)
        return { ...state, selected_data: action.value }
    }
    if (action.type === "selected_months") {
        //console.log("selected_months",action.value,state.original_data)
        var selected_data=[]
        action.value.map(item=>{selected_data=selected_data.concat(state.original_data[item])})
        var temp=[ "March","April","May","June","July","August","September","October","November","December"].filter(item=>action.value.includes(item)) // Order months
        return { ...state, selected_months: temp,selected_data:selected_data }
    }
    if (action.type === "keyword_freq_arr_by_topic") {
        //console.log("keyword_freq_arr_by_topic",action.value)
        return { ...state, keyword_freq_arr_by_topic: action.value }
    }
    if (action.type === "Selected_FlairSentimentScoreRange") {
        //console.log("Selected_FlairSentimentScoreRange",action.value)
        return { ...state, Selected_FlairSentimentScoreRange: action.value }
    }
    if (action.type === "Selected_scoresSubjectivityRange") {
        //console.log("Selected_scoresSubjectivityRange",action.value)
        return { ...state, Selected_scoresSubjectivityRange: action.value}
    }
    if (action.type === "FlairSentimentScoreRange") {
        //console.log("FlairSentimentScoreRange",action.value)
        return { ...state, FlairSentimentScoreRange: action.value}
    }
    if (action.type === "scoresSubjectivityRange") {
        //console.log("scoresSubjectivityRange",action.value)
        return { ...state, scoresSubjectivityRange: action.value}
    }
    if (action.type === "FlairSentiments") {
        //console.log("FlairSentiments",action.value)
        return { ...state, FlairSentiments: [...action.value,"Any"] }
    }
    if (action.type === "selected_sentiment") {
        //console.log("selected_sentiment",action.value)
        return { ...state, selected_sentiment: action.value }
    }
    if (action.type === "original_data") {
        var selected_data=[]
        state.selected_months.map(item=>{selected_data=selected_data.concat(action.value[item])})
        return { ...state, original_data: action.value,selected_data:selected_data}
    }
    if (action.type === "Months") {
        //console.log("Months",action.value)
        var temp=[ "March","April","May","June","July","August","September","October","November","December"].filter(item=>action.value.includes(item)) // Order months
        return { ...state, Months: temp }
    }
    return state;
}
export default reducer;