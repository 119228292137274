import React, { Component } from 'react'
import { connect } from 'react-redux'
import Grid from '@mui/material/Grid';
import Item from "./04Item"

class SimView extends Component {
    constructor(props) {
        super(props)
        this.state = { 'text_data': [],sim_result:null }
    }
    componentDidMount() {
        var topics = Object.entries(this.props.topic_month).filter(item => item[1] == "September").map(item => item[0]).slice(0,5)
        var text_per_topic = []
        topics.map(topic => {
            var counter=0
            for (var i = 0; i < this.props.original_data['September'].length; i++) {
                if (this.props.original_data['September'][i]['Topic'] == topic ) {
                    counter+=1
                    text_per_topic.push(this.props.original_data['September'][i])
                    if(counter==2){break}
                }
            }
        })
        var topic_and_docs={}
        text_per_topic.map(item=>{
            if(item['Topic'] in topic_and_docs){
                topic_and_docs[item['Topic']].push(item['Text'])
            }
            else{
                topic_and_docs[item['Topic']]=[]
                topic_and_docs[item['Topic']].push(item['Text'])
            }        
        })
        console.log(topic_and_docs)
        this.get_sim_data(topic_and_docs)
    }
    get_sim_data = (topic_and_docs) => {
        var data = {
            "topic_and_docs": topic_and_docs
        }
        fetch(" http://3.144.160.234:5000/test", {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json' }
        }).then(res => res.json())
            .then(response => {
                this.setState({sim_result:JSON.parse(response.response)})
                return response;
            }).catch(error => console.error('Error: from Json Handler', error));
    }
    render() {
        return (
            this.state.sim_result!=null?<Grid container direction="row" justifyContent="center" alignItems="center" columnGap={1} rowGap={1} key={this.props.RootContainerOpen} style={{height:"100%"}}>
                {
                    Object.keys(this.state.sim_result).map((topic,index)=><Grid item xs={2} style={{border:"1px solid grey"}}><Item topic={topic.replace(/\s/g, "")} topic_original={topic} sim_result_topic={this.state.sim_result[topic]} svgid={"svgid"+index}></Item></Grid>)
                }
            </Grid>:<h1 style={{marginTop:"20%",marginLeft:"45%"}}>Loading...........</h1>
        )
    }
}
const maptstateToprop = (state) => {
    return {
        sim_result: state.sim_result,
        topic_month: state.topic_month,
        RootContainerOpen: state.RootContainerOpen,
    }
}
const mapdispatchToprop = (dispatch) => {
    return {
        Set_RootContainerOpen: (val) => dispatch({ type: "RootContainerOpen", value: val }),
    }
}
export default connect(maptstateToprop, mapdispatchToprop)(SimView);


