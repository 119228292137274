import React, { Component } from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import * as algo1 from "../../../algo1"
import Table from "./Table"
export class Matrix extends Component {
  constructor(props) {
    super(props)
    this.state = { selected_data: null }
  }
  componentDidMount() {
    this.setState({ a: 10 })
  }
  componentDidUpdate() {
    //console.log(this.props.show_tweets)
  }
  render() {
    var matrix_width=this.props.matrix_config.space_for_topic_name+(this.props.matrix_config.max_keywords*this.props.matrix_config.cellWidth)
    return (
      <Grid container direction="row" justify="flex-start" alignItems="flex-start">
        {this.props.show_tweets.length>0?<Table show_tweets={this.props.show_tweets[0]} key={this.props.show_tweets[0][0]}> </Table>:null}
      </Grid >
    )
  }
}
const maptstateToprop = (state) => {
  return {
    show_tweets: state.show_tweets,
    matrix_config: state.matrix_config,
  }
}
const mapdispatchToprop = (dispatch) => {
  return {
    Set_Months: (val) => dispatch({ type: "Months", value: val }),
  }
}
export default connect(maptstateToprop, mapdispatchToprop)(Matrix);
