import React, { useState } from 'react';
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
const styles = theme => ({
  input: {
    height: 25,
    width:100,
  }
});
const search_box = withStyles(styles)(props => {
  const { classes } = props;
  const [search_string, setSearch_string] = useState('');
  return (
    <div className="search_box">
      <Grid container direction="row" spacing="0" style={{backgroundColor:'',padding:3}}>
        <Grid item>
          <TextField
            variant="outlined"
            label=""
            InputProps={{className: classes.input}}
            InputLabelProps={{shrink: true}}
            onChange={(event)=>setSearch_string(event.target.value)}
          />
          <Button onClick={()=>props.Set_search_string(search_string)} style={{height:25,fontSize:16,fontWeight:"bold"}}>Search</Button>
        </Grid>
      </Grid>
    </div>
  );
});
export default search_box;


/*
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button"
const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            width: '25ch',
        },
    },
}));
export default function FormPropsTextFields(props) {
    const [search_string, setSearch_string] = useState('');
    return (
        <div>
            <TextField variant="outlined" style={{marginTop:0,maxHeight:1,margin:0,padding:0}} id="standard-required" label="" defaultValue={search_string} size="small"
            onChange={(event)=>setSearch_string(event.target.value)}
            />
            <Button onClick={()=>props.Set_search_string(search_string)} style={{fontSize:16,fontWeight:"bold"}}>Search</Button>
        </div>
    );
}
*/
